import React, {useState} from 'react';
import {Button, Input, List, Modal, Space} from 'antd';
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import logo from './logo.svg';
import './App.css';

function App() {

    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [listOpen, setListOpen] = useState('Rm');
    const [inputOpen, setInputOpen] = useState('');
    const [serverList, setServerList] = useState(['']);
    const [submit, setSubmit] = useState(false);


    const showModal = () => {
        setOpen(true);
    };

    const handleSubmit = () => {
        setSubmit(true);
        fetch('/api/hello', {method: 'POST', body: password})
            .then(r => r.json())
            .then(l => {
                if (l instanceof Array) {
                    setServerList(l)
                } else {
                    setServerList([l])
                }
                setListOpen('');
                setInputOpen('Rm');
                setSubmit(false);
            })
            .catch(e => {
                setServerList([e.message])
                setListOpen('');
                setInputOpen('Rm');
                setSubmit(false);
            });

    }

    const handleCancel = () => {
        setOpen(false);
        if (!submit) {
            setListOpen('Rm');
            setPassword('');
            setInputOpen('');
            setServerList([]);
            setSubmit(false);
        }
    };


    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" onClick={showModal}/>
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
                <Modal
                    open={open}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Space.Compact style={{width: '100%'}} className={inputOpen}>
                        <Input.Password

                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                        />
                        <Button disabled={submit} type="primary" onClick={handleSubmit}>Submit</Button>
                    </Space.Compact>
                    <List
                        className={listOpen}
                        dataSource={serverList}
                        rowKey={item => item}
                        renderItem={(item) => (
                            <List.Item>
                                {item}
                            </List.Item>
                        )}
                    />
                </Modal>
            </header>
        </div>
    );
}

export default App;
